import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Button from "../components/button"
import { FaArrowLeft } from 'react-icons/fa';

export default () => (
  <div style={{ color: `teal` }}>
    <Navbar>
      <Link to="/"><FaArrowLeft /></Link>
    </Navbar>
    <Header headerText="Stuff" />
    <p>Here's some stuff I've made or written.</p>

    <p>Projects</p>

    <p>smrry.com (This website) - GatsbyJS, ReactJS, styled-components are awesome</p>
    <p>some-ruby-library - Ruby library for something</p>
    <p>changelog_generator - A small Ruby script to generate change logs from git commit messages</p>
    <p>client-search - Ruby tool to search for clients based on location radius</p>
    <p>cryptoquery - Ruby on Rails cryptocurrency ranking platform</p>
    <p>NDM Engineering - UI Design with Sketch</p>
    <p>paranormal.DB - Full Stack JS student project</p>
    <p>express-order - Mini order system that logs requests to SQLite using PHP</p>
    <p>Moneydash - Sinatra student project</p>
    <p>2DO - A basic android application made with Java</p>

    <p>Blog Posts / Articles</p>

    <p>An intro to Git for absolute beginners</p>
    <p>Git - Merging, Branching and collaborating</p>

    <p>Open Source / Open Data stuff I've worked on</p>

    <p>MyopicVicar - the Rails platform that powers FreeUKGenealogy websites</p>
    <Link to="/"><Button>Home</Button></Link>
    <Footer />
  </div>
)